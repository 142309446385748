import {useState} from 'react'

export default function NavItem({
  name,
  root,
  value,
}) {
  const [visible, setVisible] = useState(false)
  
  const humanizeName = str => {
    let result = str.replace('__', ' & ')
    result = result.replace('_', ' ')
    return result
  }
  
  
  if (typeof value === 'object') {
    return <ul>
      <button
        className='viz-btn'
        onClick={E=>setVisible(!visible)}
      >
        {humanizeName(name)}&nbsp;
        {visible ? <span>&darr;</span> : <span>&rarr;</span>}
      </button>
      
      {visible? Object.keys(value).map((key,I)=>{
        return <NavItem 
            key={I}
            name={key}
            root={name}
            value={value[key]}
          />
        })
      : null}
    </ul>
  } else {
    return <li>
      <a href={`#${root}/${name}`}>{humanizeName(name)}</a>
    </li>
  }
}