import {useState} from 'react'
import Gen1 from './Gen1.js'
import Gen2 from './Gen2.js'

const STATE = {
  GEN1: 'GEN1',
  GEN2: 'GEN2',
}


export default function() {
  const [state, setState] = useState()


  return <div>
    <h1>Light Panel Controls</h1>

    <button onClick={e=>setState(STATE.GEN1)}>1st Generation</button>

    <button onClick={e=>setState(STATE.GEN2)}>2nd Generation</button>


    {state === STATE.GEN1? <Gen1 />: null}
    {state === STATE.GEN2? <Gen2 />: null}
  </div>
}