// SaveHtmlComponent.jsx
import React, { useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';


const SaveHtmlComponent = ({component}) => {
  const componentRef = useRef();

  const handleSaveHtml = () => {
    const htmlString = ReactDOMServer.renderToString(component)

    const fullHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>My Component</title>
      </head>
      <body>
          ${htmlString}
      </body>
      </html>
    `

    const blob = new Blob([fullHtml], { type: 'text/html;charset=utf-8' })

    saveAs(blob, 'output.html');
  }


  useEffect(() => {
    // expand all details elements
    const details = componentRef.current.querySelectorAll('details')
    details.forEach(detail => {
      detail.setAttribute('open', true)
    })
  }, [])
  

  return (
    <div ref={componentRef}>
      {component}
      <button onClick={handleSaveHtml}>Save as HTML</button>
    </div>
  );
};

export default SaveHtmlComponent;
