import React from 'react'

export default function() {
  return <div>
    <h1>Smart-Cable Setup & Usage</h1>

    <h2>Basic Setup</h2>
    <ol>
      <li>Plug in the cable</li>
      <li>Plug in the device that needs to be switched</li>
      <li>On the tablet connect to the WiFi network named similar to this: <br /> 
      &nbsp;&nbsp;<em>shelly1pm-XXXX</em> 
      <h5>the part after the dash will differ on yours</h5></li>
      <li>
        Open a browser and go to <a href='http://192.168.33.1'>http://192.168.33.1</a>
      </li>
      <li>
        Tap the button in the upper right corner to switch the device on or off
      </li>
      <li>In the Timer section you can change the number of seconds after which the device will turn off</li>
    </ol>

    <h2>Advanced Setup</h2>
    <h5>This level of setup is not necessary to operate the smart-cable.</h5>
    <p>
      It is possible to connect the smart-cable to an existing WiFi network.  However, you must know either: how to find a free IP address on your network and the Gateway IP address or how to find the assigned IP address of an attached device in your router.
      However, it will be useful if you want to control multiple smart-cables from a single device.
    </p>
  </div>
}
