import React from 'react'

import image from '../../img/redlightpanel.jpg'


export default function TDSgen2() {
  return <>
    <div class="header">
      <h1>
        <img src={image} alt="Red-Light/Phototherapy Panel Image" width={64} />
        OS-300<sub>v2</sub> Technical Datasheet
      </h1>
    </div>

    <h2>Red-Light/Phototherapy Panel</h2>

    <table>
        <tr>
            <th>Model</th>
            <td>ES-300pro(gen2)</td>
        </tr>
        <tr>
            <th>LED Power Class</th>
            <td>1800W</td>
        </tr>
        <tr>
            <th>LEDs</th>
            <td>600 x 3W</td>
        </tr>
        <tr>
            <th>Amps @ 110VAC</th>
            <td>3.4A</td>
        </tr>
        <tr>
            <th>Dimensions</th>
            <td>36" x 12" x 3"</td>
        </tr>
        <tr>
            <th>Power Consumption</th>
            <td>372W</td>
        </tr>
        <tr>
            <th>Focusing Lens Beam Angle</th>
            <td>90 degree</td>
        </tr>
        <tr>
            <th>Cooling Fans</th>
            <td>6 fans</td>
        </tr>
        <tr>
            <th>Amps @ 220VAC</th>
            <td>1A</td>
        </tr>
        <tr>
            <th>Weight</th>
            <td>32lb</td>
        </tr>
        <tr>
            <th>EMF Emission</th>
            <td>0.0µT @ 4"</td>
        </tr>
        <tr>
            <th>Certifications</th>
            <td>ROHS, UL, SAA, FDA, FCC Class 1, FCC, C-TICK(AS/NZA CISPR14)</td>
        </tr>
    </table>
  </>
}
