import React from 'react'
import Guidelines from './Guidelines'
import Recipes from './Recipes'


export default function() {
  return <div>
    <h2>Frequently Asked Questions</h2>

    <summary>
      How long should my client use the light-panel?
      
      <details>
        At maximum intensity a dosage of 4 minutes can yield noticable energy increase and increased skin condition.  Longer dosages are generally safe but you will need to consult authoritative sources for more information.
      </details>
    </summary>

    <summary>
      Why isn't my panel working?
      
      <details>
        <p>
          The first thing to check is the position of the switches on the back.
          The red power switch should illuminate when on.
        </p>

        <p>
          If the red power switch does not light up in either position then unplug the unit, remove the cord, and check the fuse where the cord plugs in (it slides out with a small screwdriver or similar tool).
          If you do not know how to check the fuse then replace it- it is not always obvious when a fuse is blown.
          The fuse is a 5x20mm 250V AC.
        </p>
        
        <p>
          If the red power is illuminated.
          When the panel is on set the silver, 3-way switch to <b>S</b> position and the panel should turn on.
          If the panel does not come on then put the silver switch in the <b>App</b> position;
          there should be a blinking blue light inside the panel, visible through the vent holes.
          If the blue light is not blinking then the panel controllergit p may not be functioning correctly, contact support for more assistance.
        </p>
      </details>
    </summary>

    <Guidelines />

    <Recipes />
  </div>
}