import React from 'react'

export default function Recipes() {
  return <summary>
    Example Dosages
    
    <details>
      <b>Full Body, disrobed</b>
      
      <ul>
        <li>Maximum intensity and frequency</li>
        <li>4-8 minutes</li>
        <li>12-24 inches spacing from the panel(s)</li>
      </ul>

      <b>Full Body, light clothing</b>
      
      <ul>
        <li>Maximum intensity and frequency</li>
        <li>8-12 minutes</li>
        <li>12-18 inches spacing from the panel(s)</li>
      </ul>

      <b>Face only</b>
      
      <ul>
        <li>Red intensity and frequency at maximum</li>
        <li>NIR intensity and frequency at 25%</li>
        <li>8-10 minutes</li>
        <li>6-12 inches spacing from the panel(s)</li>
      </ul>
    </details>
  </summary>
}