import React from 'react'

import controls from "../../../img/controls.jpg"
import screen0 from "../../../img/screen0.jpg"
import screen1 from "../../../img/screen1.jpg"
import screen4 from "../../../img/screen4.jpg"
import screen2 from "../../../img/screen2.jpg"
import screen3 from "../../../img/screen3.jpg"


export default function Gen1() {
  return <>
    <p>
      The light-panel has manual and wireless control.
    </p>

    <details>
      <summary> Manual Controls </summary>

      <p>
        <img src={controls} alt="panel manual controls" />

        <h3>Red Power Switch</h3>
        <div>This switch turns on/off the main power to the unit.</div>
        
        <hr />

        <h3>Silver Selector Switch Positions:</h3>
        <ul>
          <li>
            <h4>
              <em>S</em> ~ Single mode
            </h4>
          </li>
          
          <li>
            <h4>
              <em>App</em> ~ App mode
            </h4>
          </li>
          
          <li>
            <h4>
              <em>F</em> ~ Follow mode
            </h4>
          </li>
        </ul>
        
        See below for details on when & how to implement these.
      </p>
    </details>

    <details>
      <summary>
        Example Use-Case: Manual operation
      </summary>

      <p>
        If you have an outlet controlled by a light switch:
      </p>

      <p>
        Set the switch to <em>S</em>.
        Turn the red, main-power switch on <b>|</b>.
        The light-switch can then be used to turn the panel on/off.
      </p>
    </details>

    <details>
      <summary>
        Example Use-Case: Control from a tablet
      </summary>

      <details>
        <summary>Download the App</summary>

        <p>Use the link provided by the vendor to download the app.</p>
      </details>

      <details>
        <summary>Connect to the panel</summary>

        <p>
          Turn the red, main-power switch on <b>|</b>.  Open the app and find the device listed.  It will be named with the vendor-name, eng, and 2 digits like this: <em>osteostroeng00</em>.  Then press <em>Connect</em>.

          <img src={screen0} alt="connect screen" />
        </p>
      </details>

      <details>
        <summary>Configure the panel</summary>

        <p>
          If you have multiple installations; e.g. more than one red-light room, then you will need to configure all panels in each room to have the same ID number.  To avoid confusion it's easiest to plug them in one at a time when setting the ID numbers.  
        </p>
          
        <p>
          After all the panels per room have the same ID number you will set the first panel's switch to <em>App</em> mode and all others panels to <em>F</em>ollow mode.</p>

        <p>If you only have one red-light installation then you do not need to change the ID number.</p>

        <p>
          If you do need to change the ID number: Press the <em>Device ID</em> button in the upper-left.  
          <img src={screen1} alt="main screen off" />
        </p>
          
        <p>
          Type in the ID number and save it.

          <img src={screen4} alt="device id screen" />
        </p>

        <p>
          After setting the ID numbers for the panels (see above) select one panel as the main device by switching it to <b>App</b> mode.

          Set all other panels to <b>F</b>ollow mode.
        </p>
      </details>
      
      <details>
        <summary>Configure therapeutic settings</summary>

        <p>
          If you have a group of panels setup.  You will only need to connect to the main panel (the one set to <b>App</b>.)  The secondary/follower panels with show up with an <em>F</em> after the device.  i.e. connect to the one without the <em>F</em> in the device-name.
        </p>

        <p>
          Connect to the [main] panel and press the <em>Set</em> button.  To start out, set the first 4 sliders to maximum (Hertz-RED, Hertz-NIR, Duty-Cycle-RED, Duty-Cycle-NIR).  Set the timer to the the number of minutes you need.  You can change these settings later as well.
        </p>

        <img src={screen2} alt="settings screen" />
      </details>
      
      <details>
        <summary>Usage</summary>

        <p>
          To use the panel you can simply press the circular button from the main screen (the one below the countdown timer.)

          <img src={screen3} alt="main screen" />
          <br />
          After the prescribed time the units will turn off.  Pressing the button again will turn them on for the next session.
        </p>
      </details>
    </details>
  </>
  
}
