import { useState } from 'react'
import Content from './Content'
import Footer from './Footer.js'
import Header from './Header.js'
import Menu from './Menu.js'

import './style/App.css'


function App() {
  const [menuVisibile, setMenuVisible] = useState(false)
  
  
  return <>
    <Header />
    
    <Menu
      menuVisible={menuVisibile}
      setMenuVisible={setMenuVisible}
    />
    
    <Content setMenuVisibility={setMenuVisible} />
    
    <Footer />
  </>
}


export default App