import React from 'react'


export default function() {
  return <div>
    <h3>Smart-Cable</h3>
    
    <table>
      <tr>
        <th>Power Rating</th>
        <td>16A</td>
        
        <th>Supply Voltage</th>
        <td>110-220V</td>
      </tr>
      
      <tr>
        <th>Connectivity</th>
        <td>WiFi 2.4 GHz</td>
        
        <th>IoT</th>
        <td>LAN or MQTT</td>
      </tr>
    </table>
  </div>
}