import React from 'react'


export default function() {
  return <div>
    <h3>ES-150pro+</h3>
    
    <table>
      <tr>
        <th>LED Power Class</th>
        <td>450W</td>
        
        <th>Cooling Fans</th>
        <td>2 fans</td>
      </tr>
      
      <tr>
        <th>Amps @ 110V</th>
        <td>1.96A</td>
        
        <th>Amps @ 220V</th>
        <td>0.98A</td>
      </tr>
      
      <tr>
        <th>Dimensions</th>
        <td>19" x 12" x 3"</td>
        
        <th>Weight</th>
        <td>17lbs</td>
      </tr>
      
      <tr>
        <th>Power Consumption</th>
        <td>215W</td>
        
        <th>Warranty</th>
        <td>1 Year</td>
      </tr>
      
      <tr>
        <th>LEDs</th>
        <td>150 x 3W</td>
        
        <th>Lifespan</th>
        <td>100,000hrs</td>
      </tr>
      
      <tr>
        <th>Focusing Lens Beam Angle</th>
        <td>90 Degree</td>
        
        <th>EMF Emission</th>
        <td>0.0&micro;T @ 4"</td>
      </tr>
    </table>
    
    <hr/>

    <h3>ES-300pro+</h3>
    <table>
      <tr>
        <th>LED Power Class</th>
        <td>900W</td>
        
        <th>Cooling Fans</th>
        <td>5 fans</td>
      </tr>
      
      <tr>
        <th>Amps @ 110V</th>
        <td>3.39A</td>
        
        <th>Amps @ 220V</th>
        <td>1.7A</td>
      </tr>
      
      <tr>
        <th>Dimensions</th>
        <td>36" x 12" x 3"</td>
        
        <th>Weight</th>
        <td>33lbs</td>
      </tr>
      
      <tr>
        <th>Power Consumption</th>
        <td>372W</td>
        
        <th>Warranty</th>
        <td>1 Year</td>
      </tr>
      
      <tr>
        <th>LEDs</th>
        <td>300 x 3W</td>
        
        <th>Lifespan</th>
        <td>100,000hrs</td>
      </tr>
      
      <tr>
        <th>Focusing Lens Beam Angle</th>
        <td>90 Degree</td>
        
        <th>EMF Emission</th>
        <td>0.0&micro;T @ 4"</td>
      </tr>
    </table>
    
    <hr/>

    <p>
      <em><b>Note</b></em> that these describe individual panels.  
    </p>

    <p>
      i.e. the OS-1200 is 4 of the larger panels.  When setting up wiring and mounting you would need to take into account all 4 panels.
    </p>
  </div>
}