import React from 'react'

export default function Guidelines() {
  return <summary>
    Phototherapy Guidelines
    
    <details>
      <p>
        In general, never exceed 20 minutes of exposure with our light-panels.
        Typical applications of 4-8 minutes are sufficient for excellent results.
      </p>

      <p>
        The closer the light panel is to the skin the less exposure time is required.
        The amount of light energy falling on the skin falls roughly by half for every foot a user is from the light.
        <br />
        e.g. if 4 minutes is sufficient at 12 inches, then 6 minutes yields similar results at 18 inches.
      </p>

      <p>
        Decreasing the intensity of the light increases the possible exposure time.
        e.g. an intensity of 50% at 8 minutes is roughly equivalent to 100% at 4 minutes.
        <br />
        i.e. However, this is only the raw power reaching the user- it does not account for other effects such as energy production.
        If you have a configuration that increases energy production then twice the time will yield more energy.
        But, if the light produces heat build then longer time equals more heat.
        <br />
      </p>

      <p>
        The light panel should be used in an otherwise dark room.
        Non-red light will <em>wash out</em> the red & infrared light, making it less effective.
      </p>

      <p>
        The user should not look directly into the light.
      </p>

      <p>
        If applying the light to the face:
        <br />
        Set the NIR/Infrared intensity to 50% or less.
        Alternatively, setting the pulse/frequency to 1-2Hz will effectively cut the amount of heat generated.
      </p>

      <p>
        If longer exposure times are desired lower the NIR/Infrared intensity to 50% or less.
        Alternatively, setting the pulse/frequency to 1-2Hz will effectively cut the amount of heat generated.
      </p>
    </details>
  </summary>
}