import {useState} from 'react'
import NavItem from './NavItem'
import data from './nav'


export default function({
  menuVisible,
  setMenuVisible,
}) {
  const [state, setState] = useState()
  
  
  return <>
    <button
      className="menu-button"
      onClick={E=>setMenuVisible(!menuVisible)}
    >
      &equiv; Start &equiv;
    </button>
    
    {menuVisible? 
      <nav>
        <ul>
          {Object.keys(data).map((key,I)=>{
            return <NavItem 
              key={I}
              name={key}
              value={data[key]}
              setState={setState}
            />
          })}
        </ul>
      </nav>
    : <></>}
  </>
}