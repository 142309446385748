import React from 'react'

import panel_mount from "../../img/panel_mount.jpg"
import es150_wall_mount from "../../img/es150_wall_mount.jpg"
import wall_mount_slim from "../../img/wall_mount_slim.jpg"
import quad_wall_mount from "../../img/quad_wall_mount.jpg"
import rolling_stand_es_1200_front from "../../img/rolling_stand_es-1200_front.jpg"
import rolling_stand_es_1200_back from "../../img/rolling_stand_es-1200_back.jpg"


export default function() {
  return <div>
    <h1>Light Panel Assembly</h1>

    <p>
      The light-panel itself comes pre-assembled.
      Installation consists of attaching the mount to the panel and to the mounting surface (such as a wall), and setting up the app.
    </p>


    <details>
      <summary>
        Here is one example of attaching the mount to a panel:
      </summary>

      <img src={panel_mount} alt="panel mount attachment" />
    </details>

    <details>
      <summary>
        Here are some options for mounting panels:
      </summary>

      The sky is the limit with mounting panels.  Here we have several examples for common installations depending on your space.

      <details>
        <summary>
          Wall mounting (slim)
        </summary>
        
        <p>
          Here is an installation of a single column of panels on a wall:
        </p>
        
        <img src={es150_wall_mount} alt="es-150 mounted on a wall" />
        
        <img src={wall_mount_slim} alt="es-450 mounted on a wall" />
      </details>
      
      <details>
        <summary>
          Wall mounting (wide)
        </summary>
        
        <p>
          Here is an installation of multiple panels in a surround fashion.  Note that this is an unfinished installation (unpainted and without wire routing):
        </p>
        
        <img src={quad_wall_mount} alt="es-150 mounted on a wall" />
      </details>
      
      <details>
        <summary>
          Stand mounting
        </summary>
        
        Panels can be mounted on any stand capable of holding the weight of the panels.  Here is an example of a  rolling TV stand:

        <img src={rolling_stand_es_1200_front} alt="es-1200 on a rolling TV stand" />

        <img src={rolling_stand_es_1200_back} alt="es-1200 on a rolling TV stand" />
      </details>
    </details>
  </div>
}
