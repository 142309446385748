import React from 'react'


export default function() {
  return <div>
    <h1>Package Contents</h1>

    <ul>
      <li>Light panel</li>
      <li>Eye protectors</li>
      <li>Door hanger</li>
      <li>Flexible hanger</li>
    </ul>
  </div>
}