import React from 'react'
import Gen2_app0 from './Gen2_app0'

import intensity from "../../../img/gen2/Intensity.png"
import main from "../../../img/gen2/Main.png"
import menu from "../../../img/gen2/Menu.png"
import pulse from "../../../img/gen2/Pulse.png"
import screen2 from "../../../img/screen2.jpg"
import screen3 from "../../../img/screen3.jpg"


export default function Gen1() {
  return <>
    <p>
      The light-panel has touchscreen and wireless control.
    </p>

    <details>
      <summary> Touchscreen Controls </summary>

      <p>
        <img src={menu} alt="panel manual controls" />

        <h3>On/Off</h3>
        <div>This button toggles the light on/off.</div>
        
        <h3>Timer</h3>
        <div>This button allows you to set the run-time for the light.</div>
        
        <h3>Pulse</h3>
        <div>These sliders control the frequency of each wavelength of light.</div>
        
        <h3>Intensity</h3>
        <div>These sliders control the intensity/brightness of each wavelength of light.</div>

        <h3>Set</h3>
        <div>This is where you will change the ID numbers for the panel.</div>
      </p>
    </details>

    <details>
      <summary>
        Example Use-Case: Setting up a room
      </summary>

      <p>
        You can setup all lights in a room to be controlled by a single panel or tablet.
      </p>

      <p>
        To do this all panels must have their own, unique Device ID number;  AND all panels must have the same Group ID number.
      </p>

      <p>
        To set the Device ID number: Press the <em>Set</em> button.
        Then press the <em>Device ID</em> button.
        Type in the ID number and save it by pressing <b>OK</b>.
        <br />
        e.g. If you have 8 panels in total then give them Device ID numbers 1-8.
        <br />
        e.g. If you have 5 panels in total then give them Device ID numbers 1-5.
      </p>
      
      <p>
        To set the Group ID number: Press the <em>Set</em> button.
        Then press the <em>Group ID</em> button.
        Type in the ID number and save it by pressing <b>OK</b>.
        <br />
        e.g. If you have 4 panels in one room then all those panels must have the same Group ID number.
        <br />
        i.e. If there is another panel, or panels, in a different place those panels should have a different Group ID number.
        All panels with the same Group ID number will operate together.
      </p>

      <p>
        After setting up the ID numbers you must set one panel to <b>Primary</b> by pressing <b>Set</b> and <b>Primary</b>.
        All other panels in that room/group should be set to secondary by pressing <b>Set</b> and <b>Secondary</b>.
      </p>
    </details>

    <details>
      <summary>
        Example Use-Case: Control from a tablet
      </summary>

      <details>
        <summary>Download the App</summary>

        <p>Use the link provided by the vendor to download the app.</p>
      </details>

      <details>
        <summary>Connect to the panel</summary>
        
        <p>
          With the panel powered on open the app.
          Find the device listed by it's Device ID (this number is shown on the touch-panel where is says <b>Current ID <em>N</em></b>).
          It will be named with the vendor-name followed by 1-2 digits: 
          <br />
          e.g. <em>OsteoStrong73</em>.
          <br />
          Then press <em>Connect</em>.
          <br />
          <b>The app will automatically reconnect to the previous device when it is re-opened.</b>
        </p>
      </details>

      <details>
        <summary>Basic Usage</summary>

        <p>
          Press the round "power" button below the countdown timer to begin a session.
          <br />
          Press the "power" button again to pause the session.
          <br />
          Hold the "power" button to reset the timer.
        </p>
      </details>

      <details>
        <summary>Setup the app</summary>
        
        <p>
          Press the "settings" button to reconfigure the light-panel.
          <br />
          The default PIN number is '1234'.
        </p>
        
        <p>
          <b>Disconnect</b> will disconnect from the light-panel if you need to connect to a different panel.
        </p>
        
        <p>
          <b>Device ID</b> will allow you to change the Device and/or Group ID of the connected light-panel;  if you change the ID its best to disconnect and reconnect for seamless oepration.
        </p>
        
        <p>
          <b>Intensity</b> will allow you to change the brightnes of any of the light channels (each wavelength.)
        </p>
        
        <p>
          <b>Timer</b> allows you to set the session duration.
        </p>
      </details>
    </details>
  </>
  
}
