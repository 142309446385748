const self = {
  Help: {
    Light_Panel: {
      Package_Contents: '',
      Assembly__Installation: '',
      Setup__Usage: '',
      FAQ: '',
      Indications: '',
      Specifications: '',
    },

    Smart_Cable: {
      Specifications: '',
      Setup__Usage: '',
    }
  }
}


export default self