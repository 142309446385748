import {useEffect, useState} from 'react'
import LP_Assembly from './LightPanel/Assembly.js'
import LP_Controls from './LightPanel/Controls/index.js'
import LP_FAQ from './LightPanel/FAQ/index.js'
import LP_Indications from './LightPanel/Indications.js'
import LP_PackageContents from './LightPanel/PackageContents.js'
import LP_Specifications from './LightPanel/Specifications.js'
import LP_Treatment from './LightPanel/Treatment.js'
import SC_Specifications from './SmartCable/Specifications.js'
import SC_SetupUsage from './SmartCable/SetupUsage.js'
import TDSgen2 from './Data/TDSgen2.js'
import SaveHtml from './SaveHtml.js'
import Gen2Ctrl from './LightPanel/Controls/Gen2.js'


export default function({setMenuVisibility}) {
  const [view, setView] = useState()
  
  const hashChange = event=>{
    setView(window.location.hash.substring(1).split('/'))
    setMenuVisibility(false)
  }
  
  
  useEffect(() => {
    window.addEventListener('hashchange', hashChange)
    
    hashChange()
    
    return () => {
      window.removeEventListener('hashchange', hashChange)
    }
  }, [])
  
  
  function Main({viewKey}) {
    if (viewKey) {
      const [root, page] = viewKey
      
      if (root === 'Light_Panel') {
        if (page === 'Assembly__Installation') {
          return <LP_Assembly />
        } else if (page === 'Package_Contents') {
          return <LP_PackageContents />
        } else if (page === 'Setup__Usage') {
          return <LP_Controls />
        } else if (page === 'FAQ') {
          return <LP_FAQ />
        } else if (page === 'Indications') {
          return <LP_Indications />
        } else if (page === 'Specifications') {
          return <LP_Specifications />
        } else if (page === 'Treatment') {
          return <LP_Treatment />
        } else {
          return <></>
        }
      } else if (root === 'Smart_Cable') {
        if (page === 'Specifications') {
          return <SC_Specifications />
        } else if (page === 'Setup__Usage') {
          return <SC_SetupUsage />
        } else {
          return <></>
        }
      } else if (root === 'data') {
        if (page === 'os300_gen2') {
          return <TDSgen2 />
        }
      } else if (root === 'TDS') {
        if (page === 'gen2') {
          return <SaveHtml component={[<TDSgen2 />,<Gen2Ctrl />]} />
        }
      }
    }
  }
  
  
  return <main>
    <Main viewKey={view} />
  </main>
}